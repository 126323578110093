import Link from 'next/link';

import { LaMesaRecVanLogo } from '@lamesarv-sdk/components';

interface BlogCardProps {
  link: string;
  imageUrl?: string;
  badge?: string;
  text: string;
  helper?: string;
}

export const BlogCard = ({ badge, link, text, imageUrl, helper }: BlogCardProps) => (
  <Link
    className="p-4 w-full h-full flex flex-col gap-3 items-start md:shadow-none md:hover:shadow-[0px_4px_10px_0px_#0000000D,0px_2px_20px_1px_#0000001A] shadow-[0px_4px_10px_0px_#0000000D,0px_2px_20px_1px_#0000001A] font-inter"
    href={link}
    prefetch
  >
    {imageUrl && <img className="aspect-[360/208] object-cover rounded-t-lg w-full mb-2" src={imageUrl} alt="" />}
    {!imageUrl && <LaMesaRecVanLogo className="aspect-[360/208] object-cover rounded-t-lg w-full mb-2" />}

    {badge && <span className="px-2.5 py-1 bg-[#4B6BFB1A] text-[#4B6BFB] font-medium text-sm rounded-md">{badge}</span>}
    <p className="text-[#181A2A] text-2xl font-semibold">{text}</p>
    {helper && <span className="text-sm text-[#64748B]">{helper}</span>}
  </Link>
);
