export const defaultImageUrl =
  'https://res.cloudinary.com/la-mesa-rv/image/upload/f_auto,q_auto/v1657821676/brand-assets/lamesarv/unit-in-transit_eutjhq.png';

export const mobileFiltersClass = 'mobile-filters-button';

export const inventoryItemMinWidth = 340;

export const maxSlidesInInventorySlider = 5;

export const INVENTORY_IMAGE_WIDTH = 340;
export const INVENTORY_IMAGE_HEIGHT = 285;
