import { useEffect, useState } from 'react';

import { isMobile as getIsMobile } from '@/tools/views';

export const useIsMobile = (defaultValue: boolean = true) => {
  const [isMobile, setIsMobile] = useState(defaultValue);

  useEffect(() => {
    setIsMobile(getIsMobile());

    const handleResize = () => {
      setIsMobile(getIsMobile());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};
