'use client';
import { useEnv } from '@/hooks/useEnv';
import { calculateReadingTime } from '@/tools/blog';
import { LaMesaRecVanLogo } from '@lamesarv-sdk/components';
import { getPostUrl } from '@lamesarv-sdk/tools';
import { BlogPostField, IBlogSimplePostData } from '@lamesarv-sdk/types';

import { BlogCard } from '../BlogCard';

interface IOldPostBlockProps {
  post: IBlogSimplePostData;
  ellipsisWords?: number;
  mainCategory?: string;
  mainTag?: string;
}

const OldPostBlock = ({ post, ellipsisWords, mainCategory, mainTag }: IOldPostBlockProps) => {
  const postDescription = ellipsisWords
    ? post.description.replace(/\s/g, ' ').split(' ').slice(0, ellipsisWords).join(' ') + ' ...'
    : post.description;

  return (
    <a
      href={getPostUrl(post)}
      className="flex flex-col gap-4 rounded-xl border p-6 shadow-xl hover:shadow-lg hover:bg-blue-50"
    >
      <div className="relative mb-6 h-52">
        {mainCategory && (
          <span className="absolute top-0 right-0 mt-4 mr-4 rounded-md bg-gray-600 bg-opacity-50 px-2 py-1 text-xs font-semibold uppercase text-white">
            {mainCategory}
          </span>
        )}
        {mainTag && (
          <span className="absolute top-0 right-0 mt-4 mr-4 rounded-md bg-gray-600 bg-opacity-50 px-2 py-1 text-xs font-semibold uppercase text-white">
            {mainTag}
          </span>
        )}
        {post.imageUrl && <img className="h-full w-full rounded-lg object-cover" src={post.imageUrl} alt="" />}
        {!post.imageUrl && <LaMesaRecVanLogo className="h-full w-full rounded-xl object-cover" />}
      </div>
      <h2 className="font-heading text-sage-700 text-2xl font-semibold">{post.title}</h2>
      {post.description && <p className="leading-relaxed text-gray-500">{postDescription}</p>}
      <span className="text-sage-700 font-bold hover:text-blue-500 hover:decoration-blue-500 text-xs uppercase no-underline hover:underline hover:underline-offset-4">
        Read more...
      </span>
    </a>
  );
};

interface INewPostBlockProps {
  post: IBlogSimplePostData;
}

const NewPostBlock = ({ post }: INewPostBlockProps) => {
  return (
    <BlogCard
      link={getPostUrl(post)}
      imageUrl={post[BlogPostField.imageUrl]}
      badge={post[BlogPostField.categories]?.[0]?.name}
      text={post[BlogPostField.title]}
      helper={`${new Date(post[BlogPostField.publishedAt]).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })} | ${calculateReadingTime(post[BlogPostField.body])} min read`}
    />
  );
};

type IPostBlockProps = INewPostBlockProps & IOldPostBlockProps;

export const PostBlock = (props: IPostBlockProps) => {
  const { env } = useEnv();

  if (env.ENABLE_NEW_BLOG_DESIGN) return <NewPostBlock {...props} />;

  return <OldPostBlock {...props} />;
};
