import React from 'react';

import { AutoComplete as AutoCompleteBase, ISuggestion, ISuggestions } from '@lamesarv-sdk/components';
import { useCustomSearchParams, useDebounce } from '@lamesarv-sdk/hooks';
import { useGetInventoryItems } from '@lamesarv-sdk/inventory';
import { escapeRegexCharacters, getInventoryPageUrl } from '@lamesarv-sdk/tools';
import { InventoryField, PropsWithClasses } from '@lamesarv-sdk/types';

export interface IAutoCompleteProps extends PropsWithClasses {
  placeholder?: string;
  searchPath?: string;
  sort?: string;
  amount?: number;
  onSuggestionsFetch?: (value: string) => Promise<ISuggestions[]>;
  customSuggestionMap?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [type: string]: React.FC<any>;
  };
  debounceMilliseconds?: number;
  onEnter?: (value: string, suggestions: ISuggestions[]) => void;
  defaultSearch?: string;
  onChange?: (value: string) => void;
}

export const SdkAutoComplete = (props: IAutoCompleteProps) => {
  const { loading, error, fetchItems } = useGetInventoryItems({
    skipFetch: true,
  });
  const { searchParams, setSearchParams } = useCustomSearchParams();

  const onSuggestionsFetch = async (value: string) => {
    const suggestions: ISuggestions[] = [];

    const [inventoryResults, customSuggestions] = await Promise.all([
      fetchItems({
        query: escapeRegexCharacters(value),
        sort: props.sort,
        currentPage: 0,
        perPage: props.amount ?? 5,
      }),
      props.onSuggestionsFetch?.(value),
    ]);

    const inventorySuggestions = inventoryResults.hits.map((hit) => {
      const item = hit.document;
      return {
        ...item,
        _type: 'inventory',
        _code: item[InventoryField.stockNumber],
        _title: item[InventoryField.title],
        _description: `${item[InventoryField.body]} - ${item[InventoryField.make]} - ${item[InventoryField.model]}`,
        _url: getInventoryPageUrl(item),
      };
    });

    suggestions.push({
      sectionTitle: 'Inventory Items',
      suggestions: inventorySuggestions,
    });

    if (customSuggestions) suggestions.push(...customSuggestions);

    return suggestions;
  };

  const navigateTo = (url: string) => {
    window.location.href = url;
  };

  const onSuggestionSelected = async (suggestion: ISuggestion) => {
    navigateTo(suggestion._url ?? '#');
  };

  const [onChange] = useDebounce(async (value: string) => {
    if (value) {
      setSearchParams([{ key: 'q', value }]);
    }
  }, props.debounceMilliseconds ?? 1000);

  const onEnter = (value: string, suggestions: ISuggestions[]) => {
    const searchParamsObj = new URLSearchParams({ ...searchParams.entries, q: value });
    const searchPath = props.searchPath ?? '/search';

    if (suggestions.length > 0) {
      const [inventorySuggestionGroup] = suggestions;

      const suggestion =
        inventorySuggestionGroup.suggestions.length === 1
          ? inventorySuggestionGroup.suggestions[0]
          : inventorySuggestionGroup.suggestions.find(
              (suggestion) => suggestion._code.toLowerCase() === value.toLocaleLowerCase(),
            );

      if (suggestion) {
        navigateTo(suggestion._url ?? '#');
        return;
      }
    }

    navigateTo(`${searchPath}${searchParamsObj && '?'}${searchParamsObj?.toString()}`);
  };

  return (
    <AutoCompleteBase
      placeholder={props.placeholder ?? 'Search Inventory'}
      onChange={(value) => {
        if (props.onChange) props.onChange(value);

        return onChange(value);
      }}
      onEnter={props.onEnter ?? onEnter}
      onSuggestionsFetch={onSuggestionsFetch}
      onSuggestionSelected={onSuggestionSelected}
      isLoading={loading}
      isError={error}
      className={props.className}
      classIcon={props.classIcon}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      customSuggestionMap={props.customSuggestionMap as any}
      debounceMilliseconds={props.debounceMilliseconds}
      defaultSearch={props.defaultSearch}
    />
  );
};
